import React from 'react';

import customFetch from 'dpl/shared/utils/customFetch';
import { TOAST_NOTIFICATIONS_TYPES } from 'dpl/shared/constants/shared';
import { isEmpty } from 'dpl/shared/utils/object';
import { noop } from 'dpl/shared/utils';
import { pushToastNotification } from 'dpl/shared/actions/toast_notifications';

import ErrorToastMessage from './ErrorToastMessage';
import TptConnectError from './TptConnectError';
import { RESCUABLE_STATUS_CODES } from './constants';
import { fullUrl } from './fullUrl';

export default async function createFetchData({
  body,
  headers,
  ignoreRescueOn = [],
  method = 'GET',
  params = {},
  url
}) {
  const finalUrl = isEmpty(params) ? url : fullUrl(url, params);

  const res = await customFetch(finalUrl, { headers, method, body });

  const isRescuable = !ignoreRescueOn.includes(res.status);

  if (isRescuable && RESCUABLE_STATUS_CODES.includes(res.status)) {
    window.__DPL_REDUX_STORE.dispatch(
      pushToastNotification({
        type: TOAST_NOTIFICATIONS_TYPES.ERROR,
        message: <ErrorToastMessage responseCode={res.status} />,
        timeout: false
      })
    );

    // TODO: this is temp. remove once found reason for increased 403s
    if (res.status === 403 && window.bugsnagClient) {
      const xsrfCount = (document.cookie.match(/CSRF-TOKEN/g) || []).length;
      window.bugsnagClient.notify(new Error('Server responded with 403'), {
        severity: 'warning',
        metaData: {
          url,
          xsrfCount
        }
      });
    }

    // ! Hack to resolve promise for unit tests but keeps the unresolved
    // ! noop promise for production. It is unclear if the noop is needed
    // ! for production, however, it remains for historical purposes.
    const func = CONFIG.isTest ? resolve => resolve(undefined) : noop;
    return new Promise(func);
  }

  const contentTypeHeader = res.headers.get('content-type');
  const type =
    contentTypeHeader &&
    contentTypeHeader.toLowerCase().includes('application/json')
      ? 'json'
      : 'text';

  const response = res.clone();
  const data = await response[type]();

  if (isRescuable && !response.ok) {
    throw new TptConnectError(`Server responded with ${response.status}`, {
      response: res,
      responseBody: data
    });
  }

  return { data, response };
}
