import getCsrfToken from './getCsrfToken';

const MUTATING_METHODS = ['POST', 'PATCH', 'PUT', 'DELETE'];

export default function customFetch(url, opts = {}) {
  opts.credentials || (opts.credentials = 'include');
  opts.method || (opts.method = 'GET');

  opts.headers = {
    'content-type': 'application/json',
    'x-debug-session-id': window.__DPL_DEBUG_SESSION_ID,
    accept: 'application/json',
    ...(opts.headers || {})
  };

  if (MUTATING_METHODS.includes(opts.method.toUpperCase())) {
    const token = getCsrfToken();

    if (token) {
      opts.headers['X-CSRF-Token'] = token;
    }
  }

  if (opts.body && typeof opts.body === 'object') {
    opts.body = JSON.stringify(opts.body);
  }

  return window.fetch.call(this, url, opts);
}

window.__dplFetch = customFetch;
