export default class TptConnectError extends Error {
  constructor(message, opts) {
    super(message);
    this.response = opts.response;
    this.responseBody = opts.responseBody;

    // backward compatibility
    this.error = this;
  }
}
