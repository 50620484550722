import React from 'react';
import PropTypes from 'prop-types';

import { HELP_EMAIL } from 'dpl/shared/constants/breeder_app';

export default function ErrorToastMessage({ responseCode }) {
  const { onClick = () => window.location.reload(), btnTxt = 'Reload' } =
    responseCode === 404
      ? {
          // let user go back; refreshing same page isnt helpful
          onClick: () => window.history.back(),
          btnTxt: 'Go Back'
        }
      : {};

  const debugMessage = `(status: ${responseCode}; debug-id: ${window.__DPL_DEBUG_SESSION_ID})`;

  return (
    <div className="ErrorToastMessage">
      <div className="flex mb2">
        <p>Something went wrong.</p>
        <button
          type="button"
          className="button pv1 ph2 f2 fw-medium ml3 mr1"
          onClick={onClick}
        >
          {btnTxt}
        </button>
      </div>
      <p className="f2">
        If the problem persists, please contact&nbsp;
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`mailto:${HELP_EMAIL}?body=${window.encodeURIComponent(
            debugMessage
          )}`}
          className="underline"
        >
          {HELP_EMAIL}
        </a>
        &nbsp;and include the following: {debugMessage}
      </p>
    </div>
  );
}

ErrorToastMessage.propTypes = {
  responseCode: PropTypes.number.isRequired
};
