import { useCallback, useRef } from 'react';
import { useQuery } from 'react-query';

import createFetchData from '../utils/createFetchData';
import generateQueryKey from '../utils/generateQueryKey';

export default function useResourceQuery({
  params = {},
  prefix,
  url,
  createFetchDataConfig = {},
  defaultValue = {
    meta: {},
    data: []
  },
  method = 'GET',
  ...config
}) {
  // add a version tag to the url params to bust any browser caching
  // whenever a new version of the app is deployed
  const updatedParams = params ?? {};
  if (!CONFIG.isTest) {
    updatedParams.__version = CONFIG.appVersion;
  }
  const queryKey = generateQueryKey({ params: updatedParams, prefix, url });

  const query = useQuery({
    ...(config || {}),

    queryKey,
    queryFn: () =>
      createFetchData({
        url,
        params: updatedParams,
        method,
        ...createFetchDataConfig
      })
  });

  const { data, refetch } = query;

  const hasWarnedAboutFetch = useRef(false);

  const fetch = useCallback(
    (...args) => {
      // HACK: alias fetch --> refetch since fetch was removed in v3
      if (CONFIG.isDevelopment && !hasWarnedAboutFetch.current) {
        window.console.warn(
          `fetch invoked directly on ${url} - use refetch instead`,
          prefix,
          updatedParams
        );
        hasWarnedAboutFetch.current = true;
      }

      return refetch(...args);
    },
    [refetch]
  );

  return {
    ...query,
    value: data ? data.data : defaultValue,
    queryKey,
    fetch
  };
}
