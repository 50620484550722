import * as Cookie from 'js-cookie';

import { CSRF_TOKEN } from 'dpl/shared/constants/cookies';

export default function getCsrfToken() {
  let token = Cookie.getJSON(CSRF_TOKEN);

  if (!token) {
    ({ content: token } =
      document.querySelector('meta[name=csrf-token]') || {});
  }

  return token;
}
